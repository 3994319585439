import React from "react";
import valentine_2025 from '../configs/heart_confetti';
import Particles from "@tsparticles/react";
import valentines from "../configs/valentines";

function getParamsForConfig(config) {
  switch(config) {
    case 'valentine_2025':
        return valentine_2025;
    case 'valentines':
        return valentines;
    default:
      return valentine_2025
  }
}

const ParticlesWrapper = ({ config }) => {
    return (
      <Particles
        id="tsparticles"
        options={getParamsForConfig(config)}
        height="100%"
        width="100%"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          pointerEvents: "none",
          // zIndex: -10
        }}
      />
    );

}

export default React.memo(ParticlesWrapper);
