import React, { StrictMode } from 'react';
import ReactDOM from "react-dom/client";
import './index.css';
import App from './App';

import { stopReportingRuntimeErrors } from "react-error-overlay";

if (process.env.NODE_ENV === "development") {
//   stopReportingRuntimeErrors(); // disables error overlays
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<StrictMode><App /></StrictMode>);
