import './App.css';
import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import ParticlesOld from "./components/particles";
import Messages from "./components/messages";
import Nick from './images/nick.gif';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import Moon from './components/moon/moon';
import Dropdown from './components/dropdown';
import FourYears from "./components/fouryears";
import Timer from './components/timer';
import Valentine from './components/valentine/valentine';
import Precious from './components/besafe/besafe';
import Birthday2022 from './components/birthday_2022/birthday';
import NewYear_23 from './components/newyear_2023/newyear';
import SixYearsBackground from './components/sixyears/sixyears_background';
import SixYearsText from './components/sixyears/sixyears';
import FirstMeet from './components/first_meet/firstmeet';
import Birthday2023 from './components/birthday_2023/birthday'
import FaustoBday24 from './components/fausto_bday_24/fausto_bday_24';
import { jwtDecode } from "jwt-decode";
import Birthday2024 from './components/birthday_2024/birthday';
import SpotifyButton from './components/spotify/spotify';
import Valentine2025 from './components/valentine_25/valentine_25';

import { initParticlesEngine } from "@tsparticles/react";
import { loadFull } from "tsparticles";
import { getAnalytics, logEvent } from "firebase/analytics";
import { loadConfettiPreset } from '@tsparticles/preset-confetti';

function App() {

  const [cookies, setCookie] = useCookies(['name']);
  const [signedIn, setSignedIn] = useState(false)
  const [firstComplete, setFirstComplete] = useState(false)
  const [dates, setDates] = useState(['Feb 14, 2025', 'Aug 30, 2024', 'Jan 15, 2024', 'Aug 30, 2023', 'Aug 23, 2023', 'Jun 29, 2023', 'Jan 1, 2023', 'August 30, 2022', 'April 26, 2022', 'August 30, 2021', 'June 29, 2021', 'April 02, 2021', 'March 02, 2021'])
  const [views, setViews] = useState([Valentine_2025(), BirthDay_2024(), Fausto_BirthDay_2024(), BirthDay_2023(), FirstMeeting(), SixYears(), NewYear_2023(), BirthDay_2022(), BeSafe(), BirthDay(), fouryears(), moon(), firstOne()])
  const [currentView, setCurrentView] = useState(views[0])
  const emails = ["myfanakki@gmail.com", "niveditam308@gmail.com"]

  const firebaseConfig = {
    apiKey: "AIzaSyDjJd61BKntmnOWmn8yRD7BacSqubqh7P8",
    authDomain: "mooninblack-web.firebaseapp.com",
    projectId: "mooninblack-web",
    storageBucket: "mooninblack-web.appspot.com",
    messagingSenderId: "304584892960",
    appId: "1:304584892960:web:28623476c01583d49b5245",
    measurementId: "G-V6TXR96J0C"
  };

  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    const analytics = getAnalytics(app);
    if (process.env.NODE_ENV !== "development") {
      logEvent(analytics, 'visited_app');
    }
    if (cookies.mooninblack_name && emails.includes(cookies.mooninblack_name)) {
      setSignedIn(true)
    }

    initParticlesEngine(async (engine) => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      //await loadAll(engine);
      // await loadFull(engine);
      // await loadSlim(engine);
      //await loadBasic(engine);
      await loadConfettiPreset(engine);
      // await engine.load({
      //   id: 'tsparticles',
      //   options: {
      //     preset: 'confetti',
      //     autoPlay: true,
      //     particles: {
      //       color: {
      //         value: ["#0000ff", "#00ff00"],
      //       },
      //     },
      //     "startCount": 50,
      //     "position": {
      //       "x": 50,
      //       "y": 50
      //     },
      //     "size": {
      //       "width": 0,
      //       "height": 0
      //     },
      //     "rate": {
      //       "delay": 0,
      //       "quantity": 0
      //     },
      //     "life": {
      //       "duration": 0.1,
      //       "count": 1
      //     }
      //   },
      // })
    });
  }, [])

  function onSelectDate(index) {
    setCurrentView(views[index])
  }

  function firstOne() {
    return (
      <div className="App">
      <div className="App-background App-background-first"></div>
      <div className="App-content App-content-first">
        <Messages />
      </div>
      <ParticlesOld />
      <Dropdown items={dates} onPress={onSelectDate} />
      <SpotifyButton />
      <a href="https://www.youtube.com/watch?v=LnSYihRoGA4" target="_blank">
          <img className="App-animated-nick" src={Nick} alt="sss" width="100" />
        </a>
    </div>
    )
  }

  function moon() {
    return (
      <div className="App">
        <div className="App-background App-background-first"></div>
        <div className="App-content App-content-first">
          <Moon />
        </div>
        <ParticlesOld />
        <Dropdown items={dates} onPress={onSelectDate} />
        <SpotifyButton />
      </div>
    )
  }

  function fouryears() {
    return (
      <div className="App">
        <div className="App-background App-background-third"></div>
        <div className="App-content App-content-third">
          <FourYears />
        </div>
        <Dropdown items={dates} onPress={onSelectDate} />
        <SpotifyButton />
      </div>
    )
  }

  function BirthDay() {
    return (
      <div className="App">
        <div className="App-background App-background-birthday"></div>
        <div className="App-content App-content-birthday">
          <Timer onFirstComplete={setFirstComplete}/>
        </div>
        <ParticlesOld config={firstComplete ? "balloons_birthday" : "balloons"} />
        <Dropdown items={dates} onPress={onSelectDate} />
        <SpotifyButton />
      </div>
    )
  }

  function NewYear() {
    return (
      <div className="App">
        {/* <div className="App-background App-background-new-year"></div> */}
        <ParticlesOld config={'snow'}/>
        <Dropdown items={dates} onPress={onSelectDate} />
        <SpotifyButton />
        <Valentine />
      </div>
    )
  }

  function BeSafe() {
    return (
      <div className='App'>
        <Precious />
        <Dropdown items={dates} onPress={onSelectDate} />
        <SpotifyButton />
      </div>
    )
  }

  function BirthDay_2022() {
    return (
      <div className="App">
        <Birthday2022 />
        <ParticlesOld config={"balloons"} />
        <Dropdown items={dates} onPress={onSelectDate} />
        <SpotifyButton />
      </div>
    )
  }

  function NewYear_2023() {
    return (
      <div className="App">
        <NewYear_23 />
        <Dropdown items={dates} onPress={onSelectDate} />
        <SpotifyButton />
      </div>
    )
  }

  function SixYears() {
    return (
      <div className="App">
        <SixYearsBackground />
        <div className="App-content App-content-six-years">
          <SixYearsText />
        </div>
        <Dropdown items={dates} onPress={onSelectDate} />
        <SpotifyButton />
      </div>
    )
  }

  function FirstMeeting() {
    return (
      <div className="App">
        <div className="App-background App-background-first-meeting"></div>
        <div className="App-content App-content-first-meet">
          <FirstMeet />
        </div>
        <Dropdown items={dates} onPress={onSelectDate} />
        <SpotifyButton />
      </div>
    )
  }

  function BirthDay_2023() {
    return (
      <div className="App">
        <Birthday2023 />
        <ParticlesOld config={"balloons"} />
        <Dropdown items={dates} onPress={onSelectDate} />
        <SpotifyButton />
      </div>
    )
  }

  function Fausto_BirthDay_2024() {
    return (
      <div className="App">
        <FaustoBday24 />
        <ParticlesOld config={"balloons"} />
        <Dropdown items={dates} onPress={onSelectDate} />
        <SpotifyButton />
      </div>
    )
  }

  function BirthDay_2024() {
    return (
      <div className="App">
        <div className="App-background App-background-bday-2024"></div>
        <Birthday2024 />
        <ParticlesOld config={"balloons"} />
        <Dropdown items={dates} onPress={onSelectDate} />
        <SpotifyButton />
      </div>
    )
  }

  function Valentine_2025() {
    return (
      <div className="App">
        <Valentine2025 />
        <Dropdown items={dates} onPress={onSelectDate} />
        <SpotifyButton />
      </div>
    )
  }

  // return currentView

  if (signedIn) {
    return currentView
  }

  function responseGoogle(response) {
    if (response && response.credential) {
      const decodedData = jwtDecode(response.credential);
      if (decodedData && decodedData.email) {
        if (emails.includes(decodedData.email)) {
          console.log("YES")
          setCookie('mooninblack_name', decodedData.email, { path: '/' });
          setSignedIn(true)
        } else {
          console.log("NO")
          alert("You are not authorized to access the content!")
        }
      } else {
        console.log("Something went wrong")
      }
    } else {
      console.log("Something went wrong")
    }
  }

  return (
    <>
      <GoogleOAuthProvider clientId="220617799880-pfp8otvv17to90lqgmkdqqs79mpkrb63.apps.googleusercontent.com">
        <div className="App">
          <header className="App-header-blurred" />
          <div className="App-content">
            <h4 className="App-auth-text">Please sign in with your google account to access the content</h4>
            <GoogleLogin
              onSuccess={responseGoogle}
              onError={responseGoogle}
            />
          </div>
        </div>
      </GoogleOAuthProvider>
    </>
  );
}

export default App;
