import React, { useState } from 'react';
import AkshitSmile from '../../images/akshit_smile_1_final.gif';
import AkshitSad1 from '../../images/akshit_sad_1_final.gif';
import AkshitSad2 from '../../images/akshit_sad_2_final.gif';
import AkshitHappy from '../../images/akshit_happy_1_final.gif';
import NiviSmile from '../../images/nivi_smile_final.gif';
import './valentine_25.css';
import { useEffect } from "react";
import ParticlesWrapper from '../particlesV2';
import { motion, useAnimate } from "framer-motion";
import { getAnalytics, logEvent } from "firebase/analytics";
import { tsParticles } from "@tsparticles/engine";
import { loadHeartShape } from "@tsparticles/shape-heart";
import { loadImageShape } from "@tsparticles/shape-image";
import SpinnerIcon from '@rsuite/icons/Spinner';

function Valentine2025() {

    const [hearts, setHearts] = useState(5);
    const [shake, setShake] = useState(false);
    const [noButtonText, setNoButtonText] = useState('NO :(');
    const [yesButtonText, setYesButtonText] = useState('YES!');
    const [result, setResult] = useState(false);
    const [titleText, setTitleText] = useState('Will you be my valentine?');
    const [akshitImageRef, animateAkshitImage] = useAnimate()
    const [titleRef, animateTitle] = useAnimate()
    const [yesButtonRef, animateYesButton] = useAnimate() 
    const [noButtonRef, animateNoButton] = useAnimate()
    const [buttonsDivRef, animateButtonsDiv] = useAnimate()
    const [showBottomConfetti, setShowBottomConfetti] = useState(false)
    const [randomTextIndex, setRandomTextIndex] = useState(Math.floor(Math.random() * 6) + 1);
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const resultText = [
        '',
        `YAYAYAYAYAYAYAY! We are AWESOME! Let's go! ⸜(｡˃ ᵕ ˂ )⸝♡`,
        `YAYAYAYAYAYAYAY! Thank you! I love YOU ❤︎ :)`,
        `YAYAYAYAYAYAYAY! I'm SO happeeeeeey! >ᴗ< Let's go!`,
        'YAYAYAYAYAYAYAY! YOU just made my -`♥︎´- skip a beat!',
        `YAYAYAYAYAYAYAY! I'm so excited to be with YOU! (๑ > ᴗ < ๑)`,
        `YAYAYAYAYAYAYAY! Look how cute WE are together! (˶˃ ᵕ ˂˶)`
    ];
    // const randomIndex = Math.floor(Math.random() * 6) + 1;

    const images = [
        AkshitSmile,
        AkshitSad1,
        AkshitSad2,
        AkshitHappy,
        NiviSmile
    ];

    useEffect(() => {
        // Load the script dynamically
        // const script = document.createElement("script");
        // script.src = "https://cdn.jsdelivr.net/npm/@tsparticles/confetti@3.0.3/tsparticles.confetti.bundle.min.js";
        // script.async = true;
        // document.body.appendChild(script);
    
        // return () => {
        //   document.body.removeChild(script);
        // };
        // launchConfetti()
        let loadedCount = 0;
      
          const preloadImage = (src) => {
            return new Promise((resolve) => {
              const img = new Image();
              img.src = src;
              img.onload = () => {
                loadedCount++;
                if (loadedCount === images.length) {
                  setImagesLoaded(true); // All images are loaded
                }
                resolve();
              };
              img.onerror = resolve; // Prevent blocking if image fails
            });
          };
      
          Promise.all(images.map(preloadImage));
    }, []);

    const launchConfetti = async () => {
        // const defaults = {
        //     spread: 360,
        //     ticks: 100,
        //     gravity: 0,
        //     decay: 0.94,
        //     startVelocity: 30,
        //     shapes: ["heart"],
        //     colors: ["FFC0CB", "FF69B4", "FF1493", "C71585"],
        //   };

          await loadHeartShape(tsParticles);
          await loadImageShape(tsParticles);

          tsParticles.load({
            id: 'tsparticles',
            options: {
                preset: 'confetti',
                autoPlay: true,
                // duration: {
                //     min: 100, max: 200
                // },
                // delay: {
                //     min: 1, max: 2,
                // },
                particles: {
                    // number: {
                    //     value: 10
                    // },
                    color: {
                        value: ["#FF69B4", "#FF1493", "#FE4D4B", "FE8081"],
                    },
                    size: {
                        value: {
                            max: 5,
                            min: 10
                        }
                    }, 
                    shape: {
                        type: 'heart',
                    },
                    move: {
                        // distance: 100,
                        // decay: {
                        //     max: 0.1, min: 0.2
                        // },
                        // gravity: {
                        //     enable: false,
                        //     acceleration: 0,
                        //     maxSpeed: 0
                        // }
                        enable: true,
                        speed: {
                            min: 5,
                            max: 120
                        },
                        direction: 'none',
                        outModes: {
                            default: 'destroy'
                        },
                        random: true
                    },
                    // opacity: {
                    //     value: {
                    //         max: 1,
                    //         min: 0,
                    //     },
                    //     animation: {
                    //         enable: true,
                    //         speed: 0.5,
                    //         startValue: "max",
                    //         destroy: "min",
                    //         // decay: 0.01,
                    //         // mode: "auto",
                    //         // delay: {
                    //         //     min: 0,
                    //         //     max: 1
                    //         // }
                    //     }
                    // }
                },
            },
          })
    };

    const onClickNo = async () => {
        if ("vibrate" in navigator) {
          navigator.vibrate(50); // Vibrate for 50ms
        }
        if (akshitImageRef) {
            await animateAkshitImage(akshitImageRef.current, { scale: 1.1 })
            await animateAkshitImage(akshitImageRef.current, { scale: 1 })
        }
        setShake(true);
        setTimeout(() => setShake(false), 500);
        if (hearts === 1) {
            await animateButtonsDiv(buttonsDivRef.current, { scale: 0 });
        }
        if (hearts >= 1) {
            setHearts(hearts - 1);
        }
        if (process.env.NODE_ENV !== "development") {
            const analytics = await getAnalytics();
            logEvent(analytics, 'valentines_2025_no_button_click');
        }
    };

    useEffect(() => {
        switch(hearts) {
            case 0:
                setTitleText('Okay, maybe next time (˚ ˃̣̣̥⌓˂̣̣̥ )')
                setNoButtonText('Nope');
                setYesButtonText('HAHA YES');
                break;
            case 1:
                setNoButtonText('Nope for sure');
                setYesButtonText('OF COURSE!');
                setTitleText('Okay, one more chance, will you be my valentine?')
                break;
            case 2:
                setNoButtonText('Not gonna happen');
                setYesButtonText('AYO OKAY!');
                setTitleText('Pretty please')
                break;
            case 3:
                setNoButtonText('Never');
                setYesButtonText('I WOULD ALWAYS!');
                setTitleText('Are you sure you won`t?')
                break;
            case 4:
                setNoButtonText('Nah');
                setYesButtonText('I WOULD!');
                setTitleText('You would not?')
                break;
            case 5:
                break;
            case 308:
                launchConfetti();
                setTimeout(() => {
                    setShowBottomConfetti(true);
                }, 5000);
                break;
            default:
                setNoButtonText('No :(');
                setYesButtonText('YES!');
        }
    }, [hearts])

    useEffect(() => {
        if (result && hearts < 308) {
            const timeout = setTimeout(() => {
                setHearts((prev) => prev + 1); // Increase number one by one
            }, 10); // Adjust speed by changing this value
            return () => clearTimeout(timeout);
        }
    }, [result, hearts])

    const onClickYes = async () => {
        
        if (akshitImageRef) {
            await animateButtonsDiv(buttonsDivRef.current, { scale: 0 });
            await animateTitle(titleRef.current, { scale: 0 })
            await animateAkshitImage(akshitImageRef.current, { scale: 1.1 })
            await animateAkshitImage(akshitImageRef.current, { scale: 0 })
            setResult(true);
            if ("vibrate" in navigator) {
                navigator.vibrate([4000, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200]);
            }
        }
        if (process.env.NODE_ENV !== "development") {
            const analytics = await getAnalytics();
            logEvent(analytics, 'valentines_2025_yes_button_click');
        }
    }

    const onTapHeart = async () => {
        if ("vibrate" in navigator) {
            navigator.vibrate(50); // Vibrate for 50ms
        }
        if (hearts === 0 && titleText !== 'Oh, did you change your mind?') {
            setTitleText('Oh, did you change your mind?')
            await animateNoButton(noButtonRef.current, { scale: 0 });
            await animateButtonsDiv(buttonsDivRef.current, { scale: 1 });
            
        }
        if (process.env.NODE_ENV !== "development") {
            const analytics = await getAnalytics();
            logEvent(analytics, 'valentines_2025_heart_click');
        }
    }

    function getAkshitImageForNo() {
        switch(hearts) {
            case 0:
                if (titleText === 'Oh, did you change your mind?') {
                    return AkshitSmile;
                }
                return AkshitSad2;
            case 1:
            case 2:
            case 3:
            case 4:
                return AkshitSad1;
            case 5:
                return AkshitSmile;
            default:
                return AkshitSmile;
        }
    }

    function getAkshitImageScaleForNo() {
        switch(hearts) {
            case 0:
                return 0.2;
            case 1:
                return 0.5;
            case 2:
                return 0.6;
            case 3:
                return 0.7;
            case 4:
                return 0.8;
            case 5:
                return 0.9;
            default:
                return 0.9;
        }
    }

    if (!imagesLoaded) return <div className='wrapper'>
        <SpinnerIcon pulse style={{ fontSize: '2em' }} />
        <p className='title-1' >some cute stuff coming up...</p>
    </div>;

    return (
        <div className='wrapper'>
            <div className={`heart-container ${shake ? 'shake' : ''}`}>
                <motion.img src='https://d35aaqx5ub95lt.cloudfront.net/images/hearts/7631e3ee734dd4fe7792626b59457fa4.svg'
                    whileTap={{ scale: 1.1 }} onTap={onTapHeart}/>
                <p className='heart-count' >{hearts}</p>
            </div>
            {!result && <div className='container'>
                <motion.img
                    width="100"
                    src={getAkshitImageForNo()}
                    alt={"Akshit Smile"}
                    className="rounded-lg shadow-lg"
                    initial={{ scale: 1 }}
                    // animate={{ scale: [1, 1.2, 1.1, 1] }} // Zoom in, bounce, then return to normal
                    // transition={{ duration: 0.6, ease: [0.42, 0, 0.58, 0.6] }}
                    whileHover={{ scale: 1.1 }} // Slight zoom on hover
                    whileTap={{ scale: getAkshitImageScaleForNo() }}
                    onTap={() => {
                        if ("vibrate" in navigator) {
                            navigator.vibrate(50); // Vibrate for 50ms
                        }
                    }}
                    ref={akshitImageRef}
                />
                {/* <img src={getAkshitImageForNo()} alt="Akshit Smile" width="100" /> */}
                <motion.p className='title-1' ref={titleRef}>{titleText}</motion.p>
            </div>}
            {!result && <motion.div className='button-container-1' ref={buttonsDivRef}>
                <motion.button className="duo-button primary" onClick={onClickYes} ref={yesButtonRef}>{yesButtonText}</motion.button>
                <motion.button className="duo-button negative" onClick={onClickNo} ref={noButtonRef}>{noButtonText}</motion.button>
            </motion.div>}
            {result && <div className='yes-container'>
                <div className='yes-images-container'>
                    <motion.img
                        width="100"
                        src={AkshitHappy}
                        alt={"Akshit Happy"}
                        className="rounded-lg shadow-lg"
                        initial={{ scale: 0 }}
                        animate={{ scale: [0, 1.1, 0.9, 1] }} // Zoom in, bounce, then return to normal
                        transition={{ duration: 0.6, ease: [0.42, 0, 0.58, 0.6], delay: 1.5 }}
                        // whileHover={{ scale: 1.1 }} // Slight zoom on hover
                        // whileTap={{ scale: 0.9 }}
                        
                    />
                    <motion.img
                        width="100"
                        src={NiviSmile}
                        alt={"Nivi Smile"}
                        className="rounded-lg shadow-lg"
                        initial={{ scale: 0 }}
                        animate={{ scale: [0, 1.1, 0.9, 1] }} // Zoom in, bounce, then return to normal
                        transition={{ duration: 0.6, ease: [0.42, 0, 0.58, 0.6], delay: 3 }}
                        // whileHover={{ scale: 1.1 }} // Slight zoom on hover
                        // whileTap={{ scale: 0.9 }}
                    />
                    {/* <img src={AkshitHappy} alt="Akshit Smile" width="100" /> */}
                    {/* <img src={NiviSmile} alt="Nivi Smile" width="100" /> */}
                </div>
                <motion.p className='title-1' 
                    initial={{ scale: 0 }}
                    animate={{ scale: [0, 1.1, 0.9, 1] }}
                    transition={{ duration: 0.6, ease: [0.42, 0, 0.58, 0.6], delay: 4.5 }}
                >{resultText[randomTextIndex]}</motion.p> 
                {/* <p className='title-1'>{`YAYAYAYAYAYAYAY! We are awesome! Lets go!`}</p> */}
                {/* <motion.a initial={{ scale: 0 }} animate={{ scale: [0, 1.1, 0.9, 1] }} transition={{ duration: 0.6, ease: [0.42, 0, 0.58, 0.6], delay: 4.5 }} 
                    className='title-1 link' onClick={() => window.location.href = 'ltd.love8.couples.relationship://somepath#Intent;scheme=love8;package=ltd.love8.couples.relationship;end;'}>Lets go!</motion.a> */}
            </div>}
            {showBottomConfetti && <div className='particles-container'><ParticlesWrapper config={"valentine_2025"} /></div>}
        </div>
    )
}

export default React.memo(Valentine2025);