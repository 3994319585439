import React from 'react';
import sample from '../../videos/faustobday24.mp4';
import "./fausto_bday_24.css";
import Countdown from 'react-countdown';
import { useTypewriter } from 'react-simple-typewriter';

function FaustoBirthday2024() {
    return (
        <video id="background-video" autoPlay playsInline loop muted>
            <source src={sample} type='video/mp4' />
        </video>  
    )
}

function getTextFor(type, value) {
    if (value === 0) {
      return ''
    }
    if (value > 1) {
      return type
    }
    switch(type) {
      case 'days':
        return 'day'
      case 'hours':
        return 'hour'
      case 'minutes':
        return 'minute'
      case 'seconds':
        return 'second'
    }
  }

  function Content({ text }) {
    return (
        <>
        <FaustoBirthday2024 />
        <span className='App-content fausto-birthday-2024-title'>
          <span> {text} </span>
        </span>
        </>
    )
  }
  
  const Timer = () => {
    const [text] = useTypewriter({
      words: ['happi bud day fossie!', 'thenmks u 4 existing :)'],
      loop: 1,
    })
    return <Countdown
      date={1705257001000}
    //   date={1661663881000}
      renderer={({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
          // Render a completed state
          return <Content text={text} />;
        } else {
          // Render a countdown
          return <span className='App-content fausto-birthday-2024-countdown'>{days !== 0 ? days : ''} {getTextFor('days', days)} {hours !== 0 ? hours : ''} {getTextFor('hours', hours)} {minutes !== 0 ? minutes : ''} {getTextFor('minutes', minutes)} {seconds !== 0 ? seconds : ''} {getTextFor('seconds', seconds)}</span>;
        }
      }}
    />
  }

export default Timer