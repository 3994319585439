import React, { useState, useEffect } from "react";
import paramsBalloons from "../configs/balloons";
import paramsBalloonsBirthday from "../configs/ballons_birthday";
import paramsParticles from "../configs/particles";
import maskParticles from '../configs/mask';
import snowParticles from '../configs/snow';
import ReactParticles from "react-tsparticles";
import spaceParticles from '../configs/space';
import newyear_2023 from "../configs/newyear_2023";
import valentine_2025 from '../configs/heart_confetti';

function getParamsForConfig(config) {
  switch(config) {
    case 'balloons':
      return paramsBalloons
    case 'balloons_birthday':
      return paramsBalloonsBirthday
    case 'mask':
      return maskParticles
    case 'snow':
      return spaceParticles
    case 'newyear_2023':
        return newyear_2023
    case 'valentine_2025':
        return valentine_2025;
    default:
      return paramsParticles
  }
}

const ParticlesWrapper = ({ config }) => {

    return <ReactParticles
            height="100%"
            width="100%"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              // pointerEvents: "none",
              zIndex: 10
            }}
            options={getParamsForConfig(config)}
        />
    };

export default ParticlesWrapper;
