import { useTypewriter } from 'react-simple-typewriter';


function FirstMeet() {
    const [text] = useTypewriter({
        words: ['we met for the first time :)', 'although we both were shy :(', 'i`m glad we were happy :)', 'P.S. the cute person had this donut :)'],
        loop: 1,
    })
    return (
        <span> {text} </span>
    )
}

export default FirstMeet