import React, { useState } from 'react';
import "./birthday.css";
import useCheckMobileScreen from '../../utils/checkDevice';
import { useTypewriter } from 'react-simple-typewriter';

function FirstRound(text) {
  return (
    <span> {text} </span>
  )
}

function SecondRound(isMobile) {
  
  return <div>
    <h1>happy b1rth9ay</h1>
    {isMobile ? null : <br />}
    <h6>to cute nivi</h6>
    </div>
}

function Birthday({ onFirstComplete }) {

  const [firstRoundDone, setFirstRoundDone] = useState(false)
  const [secondRoundDone, setSecondRoundDone] = useState(false)
  const [text] = useTypewriter({
      words: [
        'i just wanted to say', 
        'despite everything, i love you', 
        'and i miss you', 
        'and i think of you a countless times', 
        'and i hope for your happiness', 
        'i hope your days go well', 
        'not just today, but every coming day', 
        'even if today is sad,',
        'i hope you be excited for tomorrow',
        'anyways,',
        'happy birthday to you!'
      ],
      loop: 1,
      onLoopDone: () => {
        setTimeout(() => {
          onFirstRoundComplete();
        }, 2000);
      }
  })

  const isMobile = useCheckMobileScreen()

  function onFirstRoundComplete() {
    setFirstRoundDone(true)
    onFirstComplete(true)
  }

  function onSecondRoundComplete() {
    setSecondRoundDone(true)
  }


  return firstRoundDone ? SecondRound(isMobile) : FirstRound(text)
  return <span>Happy Birthday!</span>
}

export default Birthday;