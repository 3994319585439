import React from "react";
import { useTypewriter } from 'react-simple-typewriter';

const style = { 
  fontSize: 40
}
const Messages = () => {
  const [text] = useTypewriter({
        words: [
          'Hello Nivedita!', 
          'You are a sweetie pie', 
          'You make me blush', 
          'And you make me laugh', 
          'You bring me up when i`m down', 
          'You brighten my days when they`re dark', 
          'You never lose hope', 
          'You are a fairy princess',
          'You are mine',
          'I love you so much',
          'And I always will',
          'Yours, ♡'
        ],
        loop: 1,
    })

  return <span style={style}> {text} </span>
}

export default Messages;
