import React from "react";
import { useTypewriter } from 'react-simple-typewriter';

const Messages = () => {
  const [text] = useTypewriter({
      words: ['1', '2', '3', '4', '5', '6 years!'],
      loop: 1,
  })
  return <h4>
    <span> {text} </span>
</h4>
}

export default Messages;
