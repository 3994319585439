import React from 'react'
import { IconButton } from 'rsuite';
import { Icon } from '@rsuite/icons';
import '../../App.css';
import * as faSpotify from '@fortawesome/free-brands-svg-icons/faSpotify';

const FaSvgIcon = ({ faIcon, ...rest }) => {
    const { width, height, svgPathData } = faIcon;
    return (
      <svg {...rest} viewBox={`0 0 ${width} ${height}`} width="1.5em" height="1.5em" fill="#1DB954">
        <path d={svgPathData}></path>
      </svg>
    );
  };

const SpotifyButton = ({ items, onPress }) => {

    return <div className="App-spotify-dropdown">
        <IconButton appearance="subtle" icon={<Icon as={FaSvgIcon} faIcon={faSpotify} />} circle onClick={() => window.open('https://open.spotify.com/playlist/6rdaboKHmnqYUgiOMqgZ6B?si=9A66IVtNT-ylp_Gfbket5w&pt=35777681f7252f321edbc5dabb8baacd&pi=9JGmgjSuQOup9')}/>
    </div>
}

export default SpotifyButton