import React from 'react'
import { Dropdown, Popover } from 'rsuite';
import MenuIcon from '@rsuite/icons/Menu';
import PageIcon from '@rsuite/icons/Page';
import "rsuite/dist/rsuite.min.css";

import '../App.css';
// TODO: This is missing functionality for sub-menu here from SUI core examples.
// The "Publish To Web" item should contain a sub-menu.

const DropdownExampleDropdown = ({ items, onPress }) => (
  <div className="App-dropdown">
    <Dropdown noCaret
      placement="leftStart"
      icon={<MenuIcon style={{ fontSize: 14, background: 'transparent' }} />}
      title={''}
      style={{ background: 'transparent' }}
    >
      {items.map((item, index) => {
        return <Dropdown.Item onSelect={() => onPress(index)} key={item}>
        {item}
      </Dropdown.Item>
      })}
    </Dropdown>
  </div>
)

export default DropdownExampleDropdown