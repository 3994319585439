import React from "react";
import { useTypewriter } from 'react-simple-typewriter';

const style = { 
  fontSize: 40
}
const Messages = () => {
  const [text] = useTypewriter({
        words: [
          'love', 
          'talking', 
          'happiness', 
          'annoying each other', 
          'getting to know each other', 
          'being there for each other', 
          'caring for each other',
          'being hopeful for each other',
          'being patient with each other', 
          'holding on',
          'friendship'
        ],
        loop: 10
    })

  return <h4>
  four years of
  {" "}
  <span> {text} </span>
</h4>
}

export default Messages;
