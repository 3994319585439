export default {
  "fullScreen": {
    "zIndex": 1
  },
  "emitters": {
    "position": {
      "x": 50,
      "y": 50
    },
    "rate": {
      "quantity": 0, 
      "delay": 0.1
    },
    "life": {
      "duration": 0.1, 
      "count": 1
    },
    "size": {
      "width": 0,
      "height": 0
    }
  },
  "particles": {
    "number": {
      "value": 0
    },
    "color": {
      "value": [
        "#FFC0CB",
        "#FF69B4",
        "#FF1493",
        "#C71585"
      ]
    },
    "move": {
      "decay": 0.05,
      "enable": true,
      "speed": {
        "min": 50,
        "max": 100
      },
      "direction": "none",
      "outModes": {
        "default": "destroy",
        "top": "none"
      },
      "gravity": {
        "enable": true
      }
      // "random": true
    },
    "opacity": {
      "value": 1,
      "animation": {
        "enable": true,
        "speed": 1,
        "startValue": "max",
        "destroy": "min"
      }
    },
    "rotate": {
        "animation": {
            "enable": true,
            "speed": 30
        },
        "direction": "random",
        "value": {
            "max": 360,
            "min": 0
        }
    },
    "tilt": {
            "animation": {
                "enable": true,
                "speed": 30
            },
            "direction": "random",
            "enable": true,
            "value": {
                "max": 360,
                "min": 0
            }
        },
    "size": {
      "value": {
        "min": 8,
        "max": 16
      },
      "animation": {
        "count": 1,
        "enable": true,
        "speed": 8,
        "startValue": "max",
        // "destroy": "min",
        "sync": true
      }
    },
    "shape": {
      "type": ["image"],
      "options": {
        "image": [
          {
            "src": "https://d35aaqx5ub95lt.cloudfront.net/images/hearts/7631e3ee734dd4fe7792626b59457fa4.svg",
            "width": 32,
            "height": 32,
            "particles": {
              "size": {
                "value": 8
              }
            }
          }
        ]
      }
    }
  },
  "responsive": [
        {
            "maxWidth": 1024,
            "options": {
                "particles": {
                    "move": {
                        "speed": {
                            "max": 66,
                            "min": 33
                        }
                    }
                }
            }
        }
    ]
}
;
